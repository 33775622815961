import React from 'react'
import './PortfolioCard.css'

const PortfolioCard = props => (
    <div className="PortfolioCard">
        <img src={props.image} />
        
        <h3>{props.title}</h3>
        <p>{props.text}</p>
    </div>
)

export default PortfolioCard